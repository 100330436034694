import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import './CTAButton.scss';
import classNames from 'classnames';
import Spinner from '../spinner/Spinner';
import { Link } from 'react-router-dom';

export default function CTAButton(props: CTAButtonProps) {
  const {
    appearance = 'blue',
    size = 'regular',
    withArrow,
    link,
    onClick,
    leadingIcon,
    trailingIcon,
    text,
    disabled,
    loading,
    shadow,
    className,
    external,
    selected,
    alt,
    ...buttonProps
  } = props;

  const mainProps = {
    disabled: disabled || loading,
    className: classNames(appearance, size, 'base-button', {
      disabled,
      link,
      loading,
      selected
    }),
    onClick
  };
  const spinnerColor = ['empty', 'inverted-empty', 'empty-no-frame', 'empty-red-frame', 'white'].includes(appearance) ? undefined : 'white';
  const loader = loading && <Spinner color={spinnerColor} />;

  const textComponent = (!leadingIcon && loader) || (
    <>
      <div className="button-text">{text}</div>
      {withArrow && <span className="arrow">&nbsp;&#8594;</span>}
    </>
  );

  const content = (
    <>
      {leadingIcon && <CTAButtonIconComponent icon={leadingIcon} loader={loader} className="leading-icon" />}
      {textComponent}
      {trailingIcon && <CTAButtonIconComponent icon={trailingIcon} loader={loader} className="trailing-icon" />}
    </>
  );

  return (
    <div
      title={alt}
      className={classNames('cta-button-container', className, {
        'shadow-button': shadow
      })}
    >
      {!link && (
        <button type="button" {...mainProps} {...buttonProps}>
          {content}
        </button>
      )}
      {link && (
        <Link to={link} target={external ? '_blank' : '_self'} rel="noreferrer" {...mainProps}>
          {content}
        </Link>
      )}
    </div>
  );
}

export interface CTAButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  link?: string;
  text?: string | ReactElement;
  appearance?: CTAButtonAppearance;
  withArrow?: boolean;
  size?: 'regular' | 'small';
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  loading?: boolean;
  onClick?: () => void;
  shadow?: boolean;
  external?: boolean;
  selected?: boolean;
  alt?: string;
}

export type CTAButtonAppearance =
  | 'blue'
  | 'light-blue'
  | 'inverted'
  | 'inverted-light-blue'
  | 'dark'
  | 'empty'
  | 'inverted-empty'
  | 'empty-no-frame'
  | 'empty-red-frame'
  | 'light-blue-bg'
  | 'white'
  | 'secondary-grey'
  | 'oauth-login';

export function CTAButtonIconComponent(props: CTAButtonIconComponentProps) {
  const { icon, loader, className } = props;
  return <div className={`${className} cta-button-icon`}>{loader || icon}</div>;
}

interface CTAButtonIconComponentProps {
  icon: React.ReactNode;
  loader: React.ReactNode;
  className: string;
}

import React from 'react';
import { TenorRate } from '@grain/admin-console-api-types';
import { useParams } from 'react-router-dom';
import { PartnerOrganizationsSection } from './organizations/PartnerOrganizationsSection';
import DetailsPage from '../layout/DetailsPage';
import { convertCurrencyStringToCurrencyPair } from '@grain/rate-utils';
import { PartnerCustomersSection } from './customers/PartnerCustomersSection';
import { PartnerForwardPercentageByCountrySection } from './hedge-percentage/PartnerForwardPercentageByCountrySection';
import { PartnerCurrencyPairsSection } from './currency-pairs/PartnerCurrencyPairsSection';
import { PartnerWalletsSection } from './wallets/PartnerWalletsSection';
import { useAddCurrencyPair, useDeleteCurrencyPair } from './currency-pairs/hooks';
import { PartnerUsersSection } from './users/PartnerUsersSection';
import { usePartner } from '../../hooks/usePartner';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import { PartnerPageHeader } from './PartnerPageHeader';
import { useUpdatePartnerConfig } from './hooks/useUpdatePartnerConfig';
import { PartnerFilesSection } from './manual-hedging/PartnerFilesSection';

export function PartnerPage() {
  const params = useParams<{ partnerId: string }>();
  const { partner, isLoadingPartner, isErrorLoadingPartner } = usePartner(params.partnerId);

  const { addCurrencyPair } = useAddCurrencyPair();
  const { deleteCurrencyPair } = useDeleteCurrencyPair();
  const { updatePartnerConfig } = useUpdatePartnerConfig();

  if (!partner) {
    if (isErrorLoadingPartner) {
      return (
        <DetailsPage className="partner-page-container" title="View Partner" loading={isLoadingPartner}>
          <div>Failed to load partner</div>
        </DetailsPage>
      );
    }

    if (isLoadingPartner) {
      return (
        <DetailsPage className="partner-page-container" title="View Partner" loading>
          Loading partner...
        </DetailsPage>
      );
    }

    // should not happen
    return null;
  }

  const updateCurrencyPairTenorMarkup = async (tenorRates: TenorRate, currencyPair: string) => {
    const currencyPairTenorMarkup = partner.config?.currencyTenorMarkup || {};
    currencyPairTenorMarkup[currencyPair] = tenorRates;
    await updatePartnerConfig({
      partnerId: partner.id,
      config: { currencyTenorMarkup: currencyPairTenorMarkup }
    });
    notifySuccess('Currency pair tenor markup updated successfully');
  };

  return (
    <DetailsPage className="partner-page-container-v2" title="View Partner" loading={isLoadingPartner}>
      <div className="flex flex-col w-full">
        <PartnerPageHeader partner={partner} />

        <span className="text-secondary-grey font-medium text-sm mt-8 ml-2">Settings</span>
        <div className="flex flex-col gap-6 mt-5 pb-12">
          <PartnerCurrencyPairsSection
            partnerId={partner.id}
            addPair={(pairToAdd, settlementType) =>
              addCurrencyPair({
                partnerId: partner.id,
                currencyPair: {
                  ...convertCurrencyStringToCurrencyPair(pairToAdd),
                  settlementType
                }
              })
            }
            removePair={(currencyPairToDelete) =>
              deleteCurrencyPair({
                partnerId: partner.id,
                currencyPairId: currencyPairToDelete.id
              })
            }
            currencyTenorMarkup={partner.config.currencyTenorMarkup}
            updateCurrencyPairTenorMarkup={updateCurrencyPairTenorMarkup}
          />
          <PartnerWalletsSection partner={partner} />
          <PartnerUsersSection partnerId={partner.id} />
          <PartnerOrganizationsSection partner={partner} />
          <PartnerForwardPercentageByCountrySection partnerId={partner.id} />
          <PartnerCustomersSection partnerId={partner.id} />
          <PartnerFilesSection partnerId={partner.id} />
        </div>
      </div>
    </DetailsPage>
  );
}

import * as Sentry from '@sentry/react';
import { reactRouterV6BrowserTracingIntegration, replayIntegration, type User as SentryUser } from '@sentry/react';
import environment from '../environments/environment';
import { buildNum } from '../version';
import { REQUEST_CANCELLED_ERROR_MESSAGE, TOKEN_EXPIRED_ERROR_MESSAGE } from '@grain/api-utils';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

class AlertsService {
  private static instance: AlertsService;
  private isInitialized: boolean;

  private constructor() {
    this.isInitialized = false;
  }

  public static getInstance(): AlertsService {
    if (!this.instance) {
      this.instance = new AlertsService();
    }
    return this.instance;
  }

  public initReactAlerts() {
    if (environment.enableAlerts) {
      const dsn = environment.sentryDSN;
      if (dsn) {
        Sentry.init({
          dsn,
          integrations: [
            reactRouterV6BrowserTracingIntegration({
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            }),
            replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
              networkDetailAllowUrls: [window.location.origin, /.*\.grainfinance.co.*/, /.*\.pages.dev.*/],
              networkRequestHeaders: ['Origin', 'X-Requested-With', 'Accept'],
              networkResponseHeaders: [
                'Content-Security-Policy',
                'Strict-Transport-Security',
                'X-Content-Type-Options',
                'X-Frame-Options',
                'Referrer-Policy',
                'Permissions-Policy',
                'Access-Control-Allow-Origin',
                'Access-Control-Allow-Methods',
                'Access-Control-Allow-Headers',
                'Cross-Origin-Opener-Policy',
                'Cross-Origin-Embedder-Policy',
                'Cross-Origin-Resource-Policy',
                'Cache-Control',
                'X-Grain-Request-Id'
              ]
            })
          ],
          release: buildNum,
          tracesSampleRate: 1.0,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
          ignoreErrors: [REQUEST_CANCELLED_ERROR_MESSAGE, TOKEN_EXPIRED_ERROR_MESSAGE],
          sendDefaultPii: true,
          denyUrls: [/cloudflareinsights.com/]
        });
        Sentry.setTag('app_name', 'dashboard');
        this.isInitialized = true;
      } else {
        // eslint-disable-next-line no-console
        console.log('failed initializing alerts - ENABLE_ALERTS is set but Sentry DSN is missing');
      }
    }
  }

  public setUser(id: string, userProperties: SentryUser) {
    if (this.isInitialized) {
      Sentry.setUser({ id, ...userProperties });
    }
  }

  public captureException(err: Error) {
    if (this.isInitialized) {
      Sentry.captureException(err);
    }
  }

  public captureMessage(message: string, severity: Sentry.SeverityLevel = 'info') {
    if (this.isInitialized) {
      Sentry.captureMessage(message, severity);
    }
  }

  public createReduxEnhancer() {
    if (this.isInitialized) {
      return Sentry.createReduxEnhancer();
    }
    return null;
  }
}

export default AlertsService;

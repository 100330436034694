import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import '@grain/web-components/styles/index.scss';
import { store } from './store/store';
import { Provider } from 'react-redux';
import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import { initializeService } from './services/service-initializer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AlertsService } from '@grain/web-utils';

AlertsService.getInstance().initReactAlerts();
initializeService();
countries.registerLocale(english);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 2
    }
  }
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />

        <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      </Provider>
    </QueryClientProvider>
  </StrictMode>
);

import React, { type ReactElement } from 'react';
import type { Row, Table as ReactTable } from '@tanstack/react-table';
import { Paginator } from '../paginator/Paginator';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import './Table.scss';
import { TableEmptyState } from '../empty-states/TableEmptyState';
import { ReactComponent as TableEmptyStateIcon } from './TableEmptyStateIcon.svg';
import classNames from 'classnames';
import cn from 'classnames';
import { LinearProgress } from '@mui/material';

export const rowHeight = '56px';

export interface TableProps<TData> {
  dataTestId?: string;
  table: ReactTable<TData>;
  loading?: boolean;
  onRowClick?: (row: Row<TData>) => void;
  isFixedLayout?: boolean;
  rightPaginatorContent?: React.ReactNode;
  className?: string;
}

export function Table<TData>(props: TableProps<TData>): ReactElement {
  const { table, loading, dataTestId, className } = props;

  const withPagination = Boolean(table.options.initialState?.pagination || table.options.state?.pagination);
  const showEmptyState = !table.getRowModel().rows.length && !loading;

  return (
    <div className={cn('table-container', className)} data-testid={dataTestId}>
      <div className="table-wrapper relative">
        {loading && <LinearProgress className="table-body-loader" color="inherit" />}
        <table
          className={classNames('table', {
            'table-fixed': props.isFixedLayout
          })}
        >
          {showEmptyState ? (
            <tbody>
              <tr>
                <td colSpan={table.getAllColumns().length} style={{ width: '100%', height: rowHeight, padding: 0 }}>
                  <TableEmptyState>
                    <TableEmptyStateIcon />
                    No results
                  </TableEmptyState>
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              <TableHead table={table} />
              <TableBody onRowClick={props.onRowClick} table={table} loading={loading} />
            </>
          )}
          <TableFooter table={table} />
        </table>
      </div>
      {withPagination && (
        <div className="table-pagination">
          <hr className="table-divider" />
          <div className="flex justify-between">
            <Paginator
              onPageIndexChange={(pageIndex) => table.setPageIndex(pageIndex)}
              pageIndex={table.getState().pagination.pageIndex}
              totalItems={table.getRowCount()}
              totalPages={table.getPageCount()}
              pageSize={table.getState().pagination.pageSize}
            />

            {props.rightPaginatorContent}
          </div>
        </div>
      )}
    </div>
  );
}

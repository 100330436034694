import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import PartnersService, { GetCurrencyPairsParams } from '../services/PartnersService';

export const partnersQueries = createQueryKeyStore({
  wallets: {
    list: (partnerId: string) => {
      return {
        queryKey: ['partnerId', partnerId],
        queryFn: () => PartnersService.getInstance().getWallets(partnerId)
      };
    }
  },
  currencyPairs: {
    list: (params: GetCurrencyPairsParams) => {
      return {
        queryKey: ['partnerId', params.partnerId, 'searchTerm', params.searchTerm],
        queryFn: () => PartnersService.getInstance().getCurrencyPairs(params)
      };
    }
  },
  users: {
    list: (partnerId: string) => {
      return {
        queryKey: [partnerId],
        queryFn: () => PartnersService.getInstance().getPartnerUsers(partnerId)
      };
    }
  }
});

import React, { useEffect, useImperativeHandle } from 'react';
import './BaseTable.scss';
import baseTableStyles from './BaseTable.scss';
import {
  Cell,
  Column,
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  Row,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import BaseTablePaginator from '../table/BaseTablePaginator';
import { HeaderCheckbox, RowCheckbox } from './BaseTableSelection';
import classNames from 'classnames';
import RowExpansion from './RowExpansion';
import { ArrowRight2 } from 'iconsax-react';
import Spinner from '../spinner/Spinner';
import BaseTableHeader from './BaseTableHeader';
import BaseTableCell, { BaseTableCellCommonProps } from './BaseTableCell';
import { BaseTableEmptyState } from './BaseTableEmptyState';
import { isNil } from 'lodash';
import { TableEmptyState } from '../empty-states/TableEmptyState';
import { ReactComponent as BaseTableEmptyStateIcon } from './BaseTableEmptyStateIcon.svg';

function getColumnTextAlign<D>(column: Column<D>): string {
  switch ((column.columnDef as BaseTableColumnDef<D>).textAlignType) {
    case BaseTableColumnDefTextAlignType.Custom:
      return 'center';
    case BaseTableColumnDefTextAlignType.Percent:
    case BaseTableColumnDefTextAlignType.Amount:
    case BaseTableColumnDefTextAlignType.Date:
      return 'right';
    default:
      return 'left';
  }
}

function getCellCommonProps<D>(cell: Cell<D, any> | Header<D, any>): BaseTableCellCommonProps {
  const columnDef = cell.column.columnDef as BaseTableColumnDef<D>;
  return {
    className: columnDef.className,
    textAlign: getColumnTextAlign(cell.column),
    width: columnDef.constantWidth
  };
}

// Solves usage of forwardRef with Generics (source: https://fettblog.eu/typescript-react-generic-forward-refs/)
declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

function createInitialRowSelection<D>(data: D[], selectedData?: D[]): Record<string, boolean> {
  if (!selectedData?.length) return {};
  return Object.fromEntries(selectedData.map((partialDataItem) => [data.findIndex((dataItem) => dataItem === partialDataItem), true]));
}

const BaseTable = React.forwardRef(InnerBaseTable);

function InnerBaseTable<D>(
  {
    columns,
    data = [],
    className,
    loading,
    selectable,
    disableSorting,
    paginated,
    pageSize = 10,
    noHeader,
    largeRows,
    onRowSelectionChange,
    rowClassnameAssigner,
    paginatorSiblingElement,
    fixedLayout,
    renderSubComponent,
    initialSelectedData,
    onRowClick,
    noValuesMessage,
    initialSorting = [],
    borderless,
    columnVisibility,
    dataTestId,
    onPageIndexChange,
    totalDataCount,
    pageIndex
  }: BaseTableProps<D>,
  ref: React.ForwardedRef<BaseTableHandle>
) {
  const initialRowSelection = createInitialRowSelection(data, initialSelectedData);

  const [rowSelection, setRowSelection] = React.useState(initialRowSelection);

  const [sorting, setSorting] = React.useState<SortingState>(initialSorting);

  const [internalPagination, setInternalPagination] = React.useState({
    pageIndex: pageIndex ?? 0,
    pageSize
  });
  const isControlled = !isNil(pageIndex);

  useImperativeHandle(ref, () => ({
    async clearSelection() {
      setRowSelection({});
    }
  }));

  useEffect(() => {
    const rows: D[] = [];
    Object.keys(rowSelection).forEach((key) => {
      rows.push(data.at(+key)!);
    });
    onRowSelectionChange?.(rows);
  }, [data, onRowSelectionChange, rowSelection]);

  const [firstColumn, ...rest] = columns;
  // First column is always aligned left
  const allColumns: BaseTableColumnDef<D>[] = [{ ...firstColumn, textAlignType: BaseTableColumnDefTextAlignType.Text }, ...rest];

  if (selectable) {
    allColumns.unshift({
      id: 'select',
      header: HeaderCheckbox,
      cell: RowCheckbox,
      constantWidth: 60,
      enableSorting: false
    } as BaseTableColumnDef<D>);
  }

  const expansionColumn = React.useCallback(
    // @ts-expect-error - moving to React 18
    ({ row }) => (
      <div className="expand-arrow">
        <ArrowRight2 className={row.getIsExpanded() ? 'down' : 'right'} size={18} onClick={row.getToggleExpandedHandler()} />
      </div>
    ),
    []
  );

  if (renderSubComponent) {
    allColumns.push({
      id: 'expander',
      header: '',
      cell: expansionColumn,
      enableSorting: false,
      constantWidth: 60
    });
  }

  const table = useReactTable({
    data,
    columns: allColumns,
    state: {
      columnVisibility,
      rowSelection,
      sorting,
      pagination: internalPagination
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: !paginated || isControlled,
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setInternalPagination
  });

  useEffect(() => {
    table.resetExpanded();
  }, [data, table]);

  useEffect(() => {
    onPageIndexChange?.(internalPagination.pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalPagination.pageIndex]);

  useEffect(() => {
    if (!isControlled || internalPagination.pageIndex === pageIndex) return;
    setInternalPagination((prev) => ({ ...prev, pageIndex }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  if (data.length === 0 && !loading && noValuesMessage) {
    return <BaseTableEmptyState text={noValuesMessage} />;
  }

  const showEmptyStateV2 = !table.getRowModel().rows.length && !loading;
  const { rowHeight: baseRowHeight, largeRowsAddition: largeRowsHeightAddition } = baseTableStyles;
  const rowHeight = largeRows ? `${baseRowHeight} + ${largeRowsHeightAddition}` : baseRowHeight;
  const minTableContentHeight = `calc(${rowHeight} * ${pageSize})`;

  return (
    <div
      data-testid={dataTestId}
      className={classNames('base-table-container', className, {
        'no-header': noHeader
      })}
    >
      <div className={classNames('base-table-wrapper', { borderless })}>
        {loading && (
          <div className={classNames('base-table-overlay')}>
            <Spinner />
          </div>
        )}
        <table
          className={classNames('base-table', {
            'large-rows': largeRows,
            'fixed-layout': fixedLayout
          })}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <BaseTableHeader
                    key={`table-header-${header.id}`}
                    header={header}
                    disableSorting={disableSorting}
                    borderless={borderless}
                    {...getCellCommonProps(header)}
                  />
                ))}
              </tr>
            ))}
          </thead>

          {showEmptyStateV2 ? (
            <tbody>
              <tr className="empty-table-row" style={{ height: minTableContentHeight }}>
                <td
                  colSpan={table.getAllColumns().length}
                  rowSpan={pageSize}
                  style={{
                    width: '100%',
                    height: minTableContentHeight,
                    padding: 0
                  }}
                >
                  <TableEmptyState>
                    <BaseTableEmptyStateIcon />
                    No results
                  </TableEmptyState>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <React.Fragment key={row.id}>
                  <tr
                    className={classNames({ clickable: onRowClick }, rowClassnameAssigner?.(row.original))}
                    onClick={onRowClick ? () => onRowClick(row.original) : row.getToggleSelectedHandler()}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <BaseTableCell key={`table-cell-${cell.id}`} cell={cell} {...getCellCommonProps(cell)} />
                    ))}
                  </tr>
                  {row.getIsExpanded() && <RowExpansion<D> row={row} renderSubComponent={renderSubComponent} />}
                </React.Fragment>
              ))}
            </tbody>
          )}
        </table>
      </div>

      {paginated && (
        <div className="base-table-footer-container">
          <BaseTablePaginator table={table} numResults={totalDataCount || data.length} disabled={loading} />
          {paginatorSiblingElement}
        </div>
      )}
    </div>
  );
}

export default BaseTable;

export type BaseTableColumnDef<D> = ColumnDef<D> & {
  className?: string;
  textAlignType?: BaseTableColumnDefTextAlignType;
  constantWidth?: number;
  info?: string;
};

export enum BaseTableColumnDefTextAlignType {
  Text,
  Percent,
  Amount,
  Date,
  Custom
}

type BaseTableProps<D> = {
  columns: ColumnDef<D>[];
  data: D[];
  className?: string;
  loading?: boolean;
  disableSorting?: boolean;
  selectable?: boolean;
  paginated?: boolean;
  pageIndex?: number;
  pageSize?: number;
  noHeader?: boolean;
  largeRows?: boolean;
  rowClassnameAssigner?: (row?: D) => string;
  onRowSelectionChange?: (selectedRows: D[]) => void;
  paginatorSiblingElement?: React.ReactElement;
  onPageIndexChange?: (newPageIndex: number) => void;
  fixedLayout?: boolean;
  renderSubComponent?: (row: D) => React.ReactNode;
  initialSelectedData?: D[];
  onRowClick?: (row: D) => void;
  noValuesMessage?: string;
  initialSorting?: SortingState;
  borderless?: boolean;
  columnVisibility?: Record<string, boolean>;
  dataTestId?: string;
  totalDataCount?: number;
};

export type BaseTableHandle = {
  clearSelection: () => void;
};

export type TableRow<T> = Row<T>;

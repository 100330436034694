import CTAButton from '@grain/web-components/buttons/CTAButton';
import React, { ChangeEvent, ReactElement, useRef, useState } from 'react';
import BaseDialog, { BaseDialogHandle } from '@grain/web-components/dialogs/BaseDialog';
import { Separator } from '@grain/web-components/separator/Separator';
import BaseInput from '@grain/web-components/forms/BaseInput';
import { DashboardRole } from '@grain/core-types';
import BaseDropdown from '@grain/web-components/forms/BaseDropdown';
import './InvitePartnerUserDialog.scss';
import { isEmailValid } from '@grain/formatters';
import { getDashboardRoleNameByRole } from '@grain/web-utils';

const inviteUserEmptyState = { firstName: '', lastName: '', email: '' };

export default function InvitePartnerUserDialog(props: InviteUserDialogParams) {
  const [inviteUserState, setInviteUserState] = useState<InviteUserData>(inviteUserEmptyState);
  const [selectedRole, setSelectedRole] = useState<DashboardRole>(DashboardRole.Default);
  const [error, setError] = useState<string>('');
  const dialogRef = useRef<BaseDialogHandle>(null);

  const onSubmit = async () => {
    try {
      await props.onInvite(inviteUserState.firstName, inviteUserState.lastName, inviteUserState.email, selectedRole);
      dialogRef.current?.hideDialog();
    } catch (e) {
      setError(e.message);
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInviteUserState({ ...inviteUserState, [name]: value });
  };

  const onDialogClose = () => {
    setInviteUserState(inviteUserEmptyState);
    setSelectedRole(DashboardRole.Default);
    setError('');
  };

  const canSubmit = !!inviteUserState.firstName && !!inviteUserState.lastName && !!selectedRole && isEmailValid(inviteUserState.email);

  return (
    <BaseDialog ref={dialogRef} trigger={props.triggerComponent} onClose={onDialogClose} contentClassName={'align-all-fields'}>
      <div className="w-[28rem] h-96 flex flex-col items-center justify-between gap-4">
        <span className="mr-auto -mt-7 text-light-grey">Invite User</span>

        <Separator />

        <BaseInput
          name="firstName"
          labelText="First Name"
          value={inviteUserState.firstName}
          onChange={onInputChange}
          sideLabel={true}
          placeholder={'Enter First Name'}
          hideErrorText
          small
        />

        <BaseInput
          name="lastName"
          labelText="Last Name"
          value={inviteUserState.lastName}
          onChange={onInputChange}
          sideLabel={true}
          placeholder={'Enter Last Name'}
          hideErrorText
          small
        />

        <BaseDropdown
          labelText="Role"
          value={selectedRole}
          options={Object.values(DashboardRole)
            .filter((value) => value !== DashboardRole.Exploration)
            .map((value) => ({ text: getDashboardRoleNameByRole(value), value }))}
          onChange={(e) => setSelectedRole(e.target.value as DashboardRole)}
          sideLabel={true}
          hideErrorText
          small
        />

        <BaseInput
          name="email"
          labelText="Email"
          value={inviteUserState.email}
          onChange={onInputChange}
          sideLabel={true}
          placeholder={'example@email.com'}
          hideErrorText
          small
        />

        <Separator />

        <div className={'flex flex-row items-center w-full'}>
          <CTAButton className="mr-auto" text="Invite" disabled={!canSubmit} onClick={onSubmit} loading={props.isLoading} size={'small'} />
          <div className="text-sm text-red">{error}</div>
        </div>
      </div>
    </BaseDialog>
  );
}

type InviteUserData = {
  firstName: string;
  lastName: string;
  email: string;
};

type InviteUserDialogParams = {
  onInvite: (firstName: string, lastName: string, email: string, role: DashboardRole) => void;
  isLoading: boolean;
  triggerComponent?: ReactElement;
};
